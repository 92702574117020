export enum TDI62Key {
  TDI62EmployerName = 'tdi62.rawEmployerName',
  TDI62DolNumber = 'tdi62.rawDolNumber',
  TDI62EffectiveDater = 'tdi62.rawRequestedEffectiveDater',
  TDI62ActionTaken = 'tdi62.actionTaken',

  ActionTaken = 'actionTaken',
  RejectionReason = 'rejectionReason',

  InsuranceCarrier = 'insuranceCarrier',
  InsuranceCarrierId = 'insuranceCarrierId',
  InsuranceCarrierName = 'rawInsuranceCarrierName',
  AuthorizedRepresentativeName = 'rawAuthorizedRepresentativeName',
  Address = 'rawInsuranceCarrierAddress',
  City = 'rawInsuranceCarrierCity',
  State = 'rawInsuranceCarrierState',
  ZipCode = 'rawInsuranceCarrierZipCode',

  DOLNumber = 'rawDolNumber',
  EmployerRegisteredName = 'rawEmployerName',

  EmployeesClassCovered = 'rawEmployeeClassCovered',
  CoverageAction = 'rawCoverageAction',
  RequestEffectiveDate = 'rawRequestedEffectiveDate',
  WeeklyBenefit = 'rawWeeklyBenefit',
  DayBenefitsBeginForAccident = 'rawDayBenefitsBeginForAccident',
  MaximumBenefitPeriod = 'rawMaximumBenefitPeriod',
  DayBenefitsBeginForSickness = 'rawDayBenefitsBeginForSickness',
  AdditionalInformation = 'rawAdditionalInformation',
  ReasonForCancellation = 'rawReasonForCancellation',
  TypeOfAmend = 'rawTypeOfAmend',
  AmendNewlyEnrolledEffectiveDate = 'amendNewlyEnrolledEffectiveDateTo',
  AmendCancellationEffectiveDate = 'amendCancellationEffectiveDateTo',

  Signature = 'signature',
  SignatureDate = 'signatureDate',
  SignaturePhone = 'phoneNumber',
  SignatureEmail = 'emailAddress',
}
