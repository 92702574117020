import cn from 'classnames';
import React from 'react';

import { Tooltip, Typography } from '@mui/material';
import { IoInformationCircle } from 'react-icons/io5';
import { COLOR_CODE } from 'src/appConfig/constants';
import { isEmpty } from 'src/validations';
import View, { ViewProps } from '../View';
import './styles.scss';

const Element: React.FC<Props> = ({
  id,
  children,
  errorMessage,
  label,
  className,
  subLabel,
  required,
  infoTooltipMessage = '',
  infoTooltipPlacement = 'right',
  infoToolTipWithArrow = true,
  ...props
}) => {
  const hasError = !isEmpty(errorMessage);
  const hasLabel = !isEmpty(label);
  const hasSubLabel = !isEmpty(subLabel);

  const getTitle = () => {
    if (typeof label === 'string') return label;
    return null;
  };

  return (
    <View className={cn(className, 'form-element')} {...props}>
      {hasLabel && (
        <Typography
          variant="body2"
          classes={{
            root: 'mb-1 form-element__label',
          }}
          style={{ flexWrap: 'nowrap' }}
        >
          <View className="form-element__label-wrapper">
            <View className="form-element__label-additional-info">
              {required && (
                <span className="has-text-danger fw-bold text-is-14 form-element__required-label">
                  *
                </span>
              )}
              {infoTooltipMessage && (
                <span>
                  <Tooltip
                    arrow={infoToolTipWithArrow}
                    title={<span style={{ whiteSpace: 'pre-line' }}>{infoTooltipMessage}</span>}
                    placement={infoTooltipPlacement}
                  >
                    <i className="cursor-pointer ml-1">
                      <IoInformationCircle
                        size={16}
                        color={COLOR_CODE.INFO}
                        style={{
                          transform: 'translateY(2px)',
                        }}
                      />
                    </i>
                  </Tooltip>
                </span>
              )}
            </View>
            <span title={getTitle()}>{label} </span>
          </View>
        </Typography>
      )}

      {hasSubLabel && subLabel}
      {children}
      {hasError && (
        <Typography
          variant="subtitle1"
          color={'error'}
          classes={{
            root: 'mt-1',
          }}
        >
          {errorMessage}
        </Typography>
      )}
    </View>
  );
};

type Props = ViewProps & {
  children: React.ReactNode;
  id?: string;
  label?: string | React.ReactNode;
  errorMessage?: string;
  className?: string;
  subLabel?: string | React.ReactNode;
  required?: boolean;
  infoTooltipMessage?: string;
  infoTooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  infoToolTipWithArrow?: boolean;
};

export default Element;
